
import Hero from "./components/Hero"
import Header from "./components/Header"
import Highlights from "./components/Highlights"
import Iphone from "./components/3dphone"
import Closelook from "./components/closelook"



function App() {


  return (
  <main className="bg-black">
    <Header/>
    <Hero/>
    <Highlights/>
    <Closelook/>
  </main>
  )
}

export default App
